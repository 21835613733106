// IconEyeOff.js
import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconEyeOff.module.css';

const IconEyeOff = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.480473 0.479967C0.395794 0.564626 0.344309 0.676862 0.335378 0.796268C0.326447 0.915674 0.360663 1.03432 0.431806 1.13063L0.480473 1.18663L3.16981 3.87663C1.8591 4.79632 0.923171 6.15702 0.533139 7.70997C0.503125 7.83784 0.524631 7.97238 0.593009 8.08452C0.661387 8.19666 0.771146 8.2774 0.898564 8.30927C1.02598 8.34115 1.16083 8.32161 1.27395 8.25488C1.38708 8.18814 1.46941 8.07957 1.50314 7.95263C1.84968 6.57425 2.70154 5.37641 3.88981 4.59663L5.09647 5.8033C4.61447 6.30696 4.34887 6.97935 4.35655 7.67645C4.36424 8.37355 4.64461 9.03992 5.13761 9.53282C5.6306 10.0257 6.29702 10.306 6.99412 10.3135C7.69122 10.3211 8.36356 10.0554 8.86714 9.5733L12.8131 13.52C12.9024 13.6094 13.0221 13.6618 13.1484 13.6665C13.2746 13.6713 13.398 13.6281 13.4937 13.5456C13.5894 13.463 13.6503 13.3474 13.6642 13.2218C13.678 13.0962 13.6438 12.97 13.5685 12.8686L13.5198 12.8126L9.44447 8.73663L9.44514 8.7353L8.64514 7.93663L6.73181 6.0233H6.73314L4.81314 4.1053L4.81381 4.10397L4.05847 3.35063L1.18714 0.479967C1.09339 0.386333 0.966306 0.33374 0.833806 0.33374C0.701306 0.33374 0.574223 0.386333 0.480473 0.479967ZM5.80314 6.50997L8.15981 8.8673C7.84547 9.1709 7.42446 9.33889 6.98747 9.33509C6.55048 9.33129 6.13245 9.15601 5.82344 8.847C5.51443 8.53798 5.33915 8.11996 5.33535 7.68297C5.33155 7.24597 5.49954 6.8243 5.80314 6.50997ZM7.00047 2.66663C6.33381 2.66663 5.68714 2.7653 5.07447 2.94997L5.89914 3.77397C7.32551 3.49154 8.80586 3.76802 10.0341 4.54625C11.2624 5.32448 12.1447 6.54495 12.4985 7.9553C12.533 8.08125 12.6155 8.18869 12.7282 8.25464C12.8409 8.32059 12.975 8.33981 13.1017 8.3082C13.2284 8.27658 13.3377 8.19664 13.4063 8.08547C13.4748 7.9743 13.4971 7.84072 13.4685 7.7133C13.1073 6.27143 12.2746 4.99164 11.1027 4.07726C9.93076 3.16288 8.48689 2.66638 7.00047 2.66663ZM7.13047 5.00663L9.66447 7.53997C9.63169 6.87857 9.35418 6.25298 8.88586 5.78478C8.41754 5.31659 7.79188 5.03924 7.13047 5.00663Z" fill="#112148"/>
    </svg>
  );
};

IconEyeOff.defaultProps = { className: null };

IconEyeOff.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconEyeOff; // Ensure this is a default export
