import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldTextInput, NamedLink, H2, IconEyeOn } from '../../../components';

import css from './LoginForm.module.css';
import IconEyeOff from '../../../components/IconEyeOff/IconEyeOff';

const LoginFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        intl,
        invalid,
      } = fieldRenderProps;

      // email
      const emailLabel = intl.formatMessage({
        id: 'LoginForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'LoginForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'LoginForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'LoginForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      const [passwordVisible, setPasswordVisible] = useState(false);
      const togglePasswordVisibility = () => {
        setPasswordVisible((prev) => !prev);
      };

      // password
      const passwordLabel = intl.formatMessage({
        id: 'LoginForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'LoginForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'LoginForm.passwordRequired',
      });
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      const passwordRecoveryLink = (
        <NamedLink name="PasswordRecoveryPage" className={css.recoveryLink}>
          <FormattedMessage id="LoginForm.forgotPassword" />
        </NamedLink>
      );

      return (
        <div>
          <div className={css.welcomContainer}>
          <div className={css.welcomContent}>
            <p className={css.welcomeText}>Welcome Back!</p>
            <p className={css.subTitle}>New to AnyProperty? <a className={css.signupText} href='/signup'>Sign Up</a></p>
          </div>
          </div>
          <Form className={classes} onSubmit={handleSubmit}>
            <div>
              <FieldTextInput
                className={css.emailRoot}
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={emailLabel}
                emailLabel={css.label}
                placeholder={emailPlaceholder}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              <div className={css.passwordContainer}>
              <button
                type="button"
                className={css.togglePassword}
                onClick={togglePasswordVisibility}
                aria-label={passwordVisible ? 'Hide password' : 'Show password'}
              >
                {passwordVisible ? <IconEyeOn /> : <IconEyeOff />}
              </button>
              <FieldTextInput
                className={css.password}
                type={passwordVisible ? 'text' : 'password'}
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="current-password"
                label={passwordLabel}
                placeholder={passwordPlaceholder}
                validate={passwordRequired}
              />
              </div>
            </div>
            <div className={css.bottomWrapper}>
              <p className={css.bottomWrapperText}>
                <span className={css.recoveryLinkInfo}>
                  <FormattedMessage
                    id="LoginForm.forgotPasswordInfo"
                    values={{ passwordRecoveryLink }}
                  />
                </span>
              </p>
              <PrimaryButton className={css.loginButton} type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                <FormattedMessage id="LoginForm.logIn" />
              </PrimaryButton>
            </div>
          </Form>
        </div>
      );
    }}
  />
);

LoginFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
  inProgress: false,
};

const { string, bool } = PropTypes;

LoginFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  inProgress: bool,
  intl: intlShape.isRequired,
};

const LoginForm = compose(injectIntl)(LoginFormComponent);
LoginForm.displayName = 'LoginForm';

export default LoginForm;
